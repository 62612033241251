import React from "react"
import ListItem from "./listItem.js"

const top = [
  {
    name: "Music",
    subs: "106.5M",
    category: "Music",
    logo:
      "https://yt3.ggpht.com/FDaq8KXRu4K5nDsDY9jaCMLYmZjtHaKJn46pbYa4RDpP8HNhIJqTsgX0pdDwew7cqmeiW8Dh-6S_RmZmnzA=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC-9-kyTW8ZkZNDHQJ6FgpwQ/featured",
    rank: 1,
  },
  {
    name: "T-Series",
    subs: "101.5M",
    category: "Music",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_pAUMBrwUj9lIAPzwKL1MKmb9q875ck73n2Q=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCq-Fj5jknLsUf-MWSy4_brA",
    rank: 2,
  },
  {
    name: "PewDiePie",
    subs: "96.5M",
    category: "Entertainment",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79FVckie4j9WT-4cEW6iu3gPd4GivQf_XNSWg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/PewDiePie/featured",
    rank: 3,
  },
  {
    name: "5-Minute Crafts",
    subs: "56.6M",
    category: "How to",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_Ig-3kRzbVXf3NRh3XWDFVyzXRANCQsltkvQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UC295-Dw_tDNtZXFeAPAW6Aw",
    rank: 4,
  },
  {
    name: "Cocomelon - Nursery Rhymes",
    subs: "50.3M",
    category: "Education",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79wZ6qBUvS5bcIe_XVWu7cUdHEEZRUnK18Pcg=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/user/checkgate/about",
    rank: 5,
  },
  {
    name: "Canal KondZilla ",
    subs: "49.7M",
    category: "Music",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-yDi06ROcOe64yy81sU0pFKJUr7pyqyxXAgg=s176-c-k-c0x00ffffff-no-rj-mo",
    link: "https://www.youtube.com/user/CanalKondZilla/featured",
    rank: 6,
  },
  {
    name: "SET India ",
    subs: "49.4M",
    category: "Entertainment",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_rrvu2vunZ4PEZ0fXnz78RaMK11j-Qrv1KZw=s88-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCpEhnqL0y41EpW2TvWAHD7Q",
    rank: 7,
  },
  {
    name: "Justin Bieber ",
    subs: "45.2M",
    category: "Entertainment",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_J6Yd47Wjo8mxFwihVvPrMseioDo1n5aIXAw=s88-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCIwFjwMjI0y7PDBVEO9-bkQ",
    rank: 8,
  },
  {
    name: "WWE",
    subs: "44.5M",
    category: "Sports",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-JXrgTbV0Df7gzTZTTGi53X5pejNnn42R09g=s88-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCJ5v_MCY6GNUBTO8-D3XoAg",
    rank: 9,
  },
  {
    name: "Dude Perfect",
    subs: "42.8M",
    category: "Sports",
    logo:
      "https://yt3.ggpht.com/a/AGF-l786MhSiIQO2jrdpXgwwZxlJdThr1uRZhG0MYQ=s288-mo-c-c0xffffffff-rj-k-no",
    link: "https://www.youtube.com/channel/UCRijo3ddMTht_IHyNSNXpNQ",
    rank: 10,
  },
  {
    name: "Badabun",
    subs: "40.1M",
    category: "Entertainment",
    link: "https://www.youtube.com/channel/UCYWOjHweP2V-8kGKmmAmQJQ",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78x3T7ChRLSqiVCXCphxYL7nBPfaSERNevWHw=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 11,
  },
  {
    name: "Ed Sheeran",
    subs: "39.6M",
    category: "Music",
    link: "https://www.youtube.com/user/EdSheeran/about",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_T61FfwKxyz_YH7RTD71-Y29TqCYzk_4Ir=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 12,
  },
  {
    name: "HolaSoyGerman.",
    subs: "39.3M",
    category: "Entertainment",
    link: "https://www.youtube.com/channel/UCZJ7m7EnCNodqnu5SAtg8eQ",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_N9uLl8iqg11SKM61oDY2a6ZYdczHmiIBYBw=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 13,
  },
  {
    name: "Zee Music Company",
    subs: "38.5M",
    category: "Music",
    link: "https://www.youtube.com/user/zeemusiccompany/featured",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-o-FQ4aEdqCMNlLpCqpeF2kTtgTP33V8X5-g=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 14,
  },
  {
    name: "EminemMusic",
    subs: "37.7M",
    category: "Music",
    link: "https://www.youtube.com/channel/UCfM3zsQsOnfWNUppiycmBuw",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-nvcpJYEvwEM2WKo1nF1f74wzb3fMiu87mJg=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 15,
  },
  {
    name: "Ariana Grande",
    subs: "36.1M",
    category: "Music",
    link: "https://www.youtube.com/channel/UC9CoOnJkIBMdeijd9qYoT_g",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-lV1E-93ZFAKjZv4B48fb_BArHVPCPGHlLIA=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 16,
  },
  {
    name: "whinderssonnunes",
    subs: "35.8M",
    category: "Comedy",
    link: "https://www.youtube.com/channel/UC3KQ5GWANYF8lChqjZpXsQw",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_sALN4sLnZxs7_D60uUDH2xX1io-zTZiLFCA=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 17,
  },
  {
    name: "Marshmello",
    subs: "35.5M",
    category: "Music",
    link: "https://www.youtube.com/channel/UCEdvpU2pFRCVqU6yIPyTpMQ",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79x-NAaYftqGbGJwIbwjLXyJgdaUjLdUBWQMA=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 18,
  },
  {
    name: "JuegaGerman",
    subs: "35.1M",
    category: "Games",
    link: "https://www.youtube.com/channel/UCYiGq8XF7YQD00x7wAd62Zg",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78r93JtUd0J2x3vDRRkIrBObCLOY7wytiN3JQ=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 19,
  },
  {
    name: "elrubiusOMG",
    subs: "34.8M",
    category: "Comedy",
    link: "https://www.youtube.com/user/elrubiusOMG",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78TJZPSsMrUf2cCoAW0Jp-c02oNgxAKAfLTyg=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 20,
  },
  {
    name: "Taylor Swift",
    subs: "34.1M",
    category: "Music",
    link: "https://www.youtube.com/channel/UCqECaJ8Gagnn7YCbPEzWH6g",
    logo:
      "https://yt3.ggpht.com/a/AGF-l78QGQhnnkaQh0_WooibgTjC_ayLjlXvKNkFvQ=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 21,
  },
  {
    name: "Katy Perry ",
    subs: "33.9M",
    category: "Music",
    link: "https://www.youtube.com/channel/UCYvmuw-JtVrTZQ-7Y4kd63Q",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7_J2Wfp1KTYcGILVs6o-bS8gQ7SpD19WkRUPg=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 22,
  },

  {
    name: "Fernanfloo",
    subs: "33M",
    category: "Games",
    link: "https://www.youtube.com/user/Fernanfloo/featured",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-C3Kl93mzlX-AWt7g8snIaOp5sAheO3vh64A=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 23,
  },
  {
    name: "TheEllenShow",
    subs: "32.8M",
    category: "Entertainment",
    link: "https://www.youtube.com/user/TheEllenShow",
    logo:
      "https://yt3.ggpht.com/a/AGF-l7-vAW-s0xZI7oURqwN9_hHXlEU9W0Fy2lYtqw=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 24,
  },
  {
    name: "Felipe Neto",
    subs: "32.9M",
    category: "Entertainment",
    link: "https://www.youtube.com/user/felipeneto",
    logo:
      "https://yt3.ggpht.com/a/AGF-l79S4FrOUo9crQs2nxlwuJKOeg4O5chZcaB6jA=s288-mo-c-c0xffffffff-rj-k-no",
    rank: 25,
  },
]

const Top = () => <ListItem data={top} />

export default Top
