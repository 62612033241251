import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SelectRouter from "./selectRouter"
import Top from "../components/top"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Top YouTube Channels"
        description="youtuber.life brings you top youtube channels by subscribers"
      />
      <SelectRouter />
      <Top />
    </Layout>
  )
}

export default IndexPage
